import React, {useEffect, useState} from 'react';
import logo from './logo.png';
import './App.scss';
import {Formik} from "formik";
import axios from "axios";

const API_URL = '/api/';

function App() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [codeSent, setCodeSent] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [serverErrors, setServerErrors] = useState('');
    const [startingApp, setStartingApp] = useState(false);

    useEffect(() => {
        if (!isLoaded) {
            setCodeSent(Number(localStorage.getItem('codeSent')));
            setPhoneNumber(localStorage.getItem('phoneNumber'));
            setIsLoaded(true);
        }
    });

    const startApp = () => {
        setStartingApp(true);
        localStorage.removeItem('codeSent');
        localStorage.removeItem('phoneNumber');
        window.open('/', '_self');
        window.reload();
    };


    const sendSmsCode = async (prefix, phone) => {
        const formattedNumber = prefix + phone.replace(/\s/g, '')/*.substring(1)*/;
        axios.post(`${API_URL}get-sms-code`, {
            'phone': formattedNumber,
            'recaptcha': await getRecaptchaToken()
        })
            .then(function (response) {
                setSubmitting(false)
                localStorage.setItem('phoneNumber', formattedNumber);
                setPhoneNumber(formattedNumber);
                localStorage.setItem('codeSent', Date.now());
                setCodeSent(Date.now());
            })
            .catch(function (error) {
                setSubmitting(false);
                setServerErrors('Vyskytla sa chyba.');
                setCodeSent(0);
            })
    };

    const verifySmsCode = async (smsCode) => {
        smsCode = smsCode.trim();
        axios.post(`${API_URL}login`, {
            'phone': phoneNumber,
            'sms_code': smsCode,
            'recaptcha': await getRecaptchaToken()
        })
            .then(function (response) {
                setSubmitting(false);
                localStorage.setItem('flutter.token', `"${response.data.data.token}"`);
                setCookie('__session', response.data.data.token, 30);
                startApp();
            })
            .catch(function (error) {
                setSubmitting(false);
                setServerErrors('Vyskytla sa chyba.');
            })
    };


    const backToPhone = () => {
        localStorage.removeItem('codeSent');
        setCodeSent(0);
    };

    // eslint-disable-next-line no-undef
    const getRecaptchaToken = () => grecaptcha.execute('6Lejvt0UAAAAAGK0EpTHy_ap1y02QgIv0LR_Zex-', {action: 'login'});

    return (
        <div className="text-center m-auto">
            {!startingApp && (codeSent < Date.now() - 5 * 60 * 1000 ? <Formik
                key="phone"
                initialValues={{phone: '', prefix: '+421'}}
                validate={values => {
                    const errors = {}
                    if (!values.phone || values.phone.length < 9) {
                        errors.phone = 'Zadajte vaše telefónne číslo bez predvoľby.';
                    }/* else if (!RegExp(/^0[0-9]{3} ?[0-9]{3} ?[0-9]{3}$/).test(values.phone.trim())) {
                        errors.phone = 'Zadajte telefónne číslo v tvare 0900 123 456.';
                    }*/
                    return errors
                }}
                onSubmit={(values) => {
                    setSubmitting(true);
                    sendSmsCode(values.prefix, values.phone);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleSelect,
                  }) => (
                    <form className="content" onSubmit={handleSubmit}>
                        <Logo/>
                        <Subtitle>Zadajte telefónne číslo pod ktorým ste registrovaný v spoločnosti Finportal</Subtitle>
                        <label htmlFor="phone" className="sr-only">Telefónne číslo</label>
                        <div className="row">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                       type="radio"
                                       name="prefix"
                                       id="inlineRadio1"
                                       checked={values.prefix === "+421"}
                                       onChange={handleChange}
                                       onSelect={handleSelect}
                                       value="+421"/>
                                <label className="form-check-label" htmlFor="inlineRadio1">Slovensko</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                       type="radio"
                                       name="prefix"
                                       id="inlineRadio2"
                                       checked={values.prefix === "+420"}
                                       onChange={handleChange}
                                       onSelect={handleSelect}
                                       value="+420"/>
                                <label className="form-check-label" htmlFor="inlineRadio2">Česká republika</label>
                            </div>
                        </div>
                        <br/>
                        <div className="form-container mb-2">
                            <input type="text"
                                   disabled="true"
                                   className="form-control"
                                   style={{borderColor: (errors.phone != null ? '#dc3545' : null)}}
                                   value={values.prefix}
                                   autoFocus/>
                            <input type="tel"
                                   id="phone"
                                   name="phone"
                                   className={'form-control' + (errors.phone != null ? ' is-invalid' : '')}
                                   placeholder="Telefónne číslo"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   disabled={submitting}
                                   required
                                   autoFocus/>
                        </div>
                        <p className="errors mb-3">{errors.phone && touched.phone && errors.phone}</p>
                        <p className="errors mb-3">{serverErrors}</p>
                        <button className="btn btn-lg btn-primary px-5 border-0"
                                type="submit"
                                disabled={errors.phone != null || submitting}
                        >
                            {submitting ? <span className="spinner-border" role="status"/> : 'Prihlásiť'}
                        </button>
                        <p className="mt-5 mb-3 text-muted">&copy; 2024 Finportal, a.s. Všetky práva vyhradené.</p>
                    </form>
                )}
            </Formik> : <Formik
                key="code"
                initialValues={{smsCode: ''}}
                validate={values => {
                    const errors = {}
                    if (!values.smsCode || values.smsCode.length < 3) {
                        errors.smsCode = 'Zadajte SMS kód.'
                    } else if (!RegExp(/^[0-9]{4}$/).test(values.smsCode.trim())) {
                        errors.smsCode = 'Neplatný tvar SMS kódu.';
                    }
                    return errors
                }}
                onSubmit={(values) => {
                    setSubmitting(true);
                    verifySmsCode(values.smsCode);
                }}
            >
                {({
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                  }) => (
                    <form className="content" onSubmit={handleSubmit}>
                        <Logo/>
                        <Subtitle>Zadajte SMS kód, ktorý sme vám zaslali na telefónne číslo {phoneNumber}</Subtitle>
                        <label htmlFor="smsCode" className="sr-only">Telefónne číslo</label>
                        <input type="tel"
                               id="smsCode"
                               name="smsCode"
                               className={'form-control mb-2 text-center' + (errors.smsCode != null ? ' is-invalid' : '')}
                               placeholder="SMS kód"
                               onChange={handleChange}
                               onBlur={handleBlur}
                               disabled={submitting}
                               required
                               autoFocus/>
                        <p className="errors mb-3">{errors.smsCode && touched.smsCode && errors.smsCode}</p>
                        <p className="errors mb-3">{serverErrors}</p>
                        <button className="btn btn-lg btn-primary px-5 border-0 mb-2"
                                type="submit"
                                disabled={errors.smsCode != null || submitting}>
                            {submitting ? <span className="spinner-border" role="status"/> : 'Pokračovať'}
                        </button>
                        <button className="btn btn-outline-secondary border-0"
                                type="button"
                                onClick={backToPhone}
                                disabled={submitting}>
                            Znovu odoslať kód
                        </button>
                        <p className="mt-5 mb-3 text-muted">&copy; 2024 Finportal, a.s. Všetky práva vyhradené.</p>
                    </form>
                )}
            </Formik>)}
            {startingApp && <div className="content">
                <span className="spinner-border large mb-3" role="status"/>
                <p className="h3">Načítava sa...</p>
            </div>}
        </div>
    );
}

export default App;


const Logo = () => (<img className="logo mb-5" src={logo} alt="Finportal"/>);
const Subtitle = (props) => (<p className="mb-5 font-weight-normal">{props.children}</p>);

function setCookie(name, val, days) {
    var d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + val + ";" + expires + ";path=/";
}
